/* Old breakpoint: 650px; */
const theme = {
  breakpoints: ['54rem', '29rem'], // 680px
  menuBreakpoints: {
    menuOpen: {
      mobileForm: null,
      menuOverlay: null
    },
    menuClosed: {
      mobileForm: null,
      menuOverlay: null
    }
  },
  pageWidth: '48rem',
  margins: [
    '2rem',
    '3rem'
  ],
  dimensions: {
    topNav: '4rem',
    leftNav: '20rem'
  },
  formMargins: "2rem 1rem 1rem",
  fontSizes: [
    '0.75rem', '0.875rem', '1rem', '1.25rem', '1.5rem', '1.75rem', '2rem', '2.25rem', '3rem', '4rem'
  ],
  colors: {
    background: "#fff",
    border: "#000",
    text: "#231f20",
    label: "#dadadc",
    bullet: "#b9babd",
    link: "#ec008d",
    pullquote: {
      ideal: "#425c8d",
      flaw: "#a34f5f"
    },
    symbols: {
      step: "#000",
      ap: "#000",
      damage: "#dd2029",
    },
    roles: {
      fighter: "#f04822",
      invoker: "#0065b1",
      ranger: "#00a2ba",
      naturalist: "#5b9f44",
      doctor: "#dd2029",
      spy: "#4d3896",
      magician: "#ec008d",
      wizard: "#e2c121",
    }
  },
  radii: {
    page: "1.5rem",
  },
  numberSizes: {
    line: '2.25rem',
    field: '1.5rem'
  },
  fonts: {
    body: 'Ovo, system-ui, sans-serif',
    heading: 'Alegreya Sans SC',
    pullquote: 'Alegreya'
  },
  fontWeights: {
    body: [400],
    heading: [700],
    bold: 700,
    button: 500,
    pullquote: [900, 700],
  }
};

export default theme;