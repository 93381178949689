import roles from './roles.json';
import paths from './paths.json';
import abilities from './abilities.json';

import roles_trie from './roles.trie.json';
import paths_trie from './paths.trie.json';
import abilities_trie from './abilities.trie.json';

const types = {
  'abilities': {
    data: abilities,
    trie: abilities_trie
  },
  'paths': {
    data: paths,
    trie: paths_trie
  },
  'roles': {
    data: roles,
    trie: roles_trie
  }
}

export const getEntityDetailsByTitle = (type) => (title) => {
  const data = types[type].data;
  const res = data.find(datum => datum.title.toLowerCase() === title.toLowerCase());
  if (typeof res !== 'undefined') {
    return res;
  } else {
    return null;
  }
};

export const getEntityDetailsById = (type) => (id) => {
  const data = types[type].data;
  const res = data.find(datum => datum.id === id);
  if (typeof res !== 'undefined') {
    return res;
  } else {
    return null;
  }
}

export const getPathDetailsByAbilityId = (id) => {
  const res = paths.find(path => path.abilities.indexOf(id) > -1);
  if (res) {
    return res;
  } else {
    return null;
  }
}

const getNestedValues = (object) => {
  const values = [];

  const recurse = (object) => {
    for (const v of Object.values(object)) {
      if (typeof v === 'number') {
        values.push(v);
      } else {
        recurse(v);
      }
    }
  };

  recurse(object);
  return values;
};

export const getAutoComplete = (type) => (value) => {
  const search = value.toLowerCase();
  const data = types[type].data;
  let domain = types[type].trie;
  let result = null;

  for (const character of search) {
    const domainIsArray = Array.isArray(domain);
    if (typeof domain === 'number' || domainIsArray) {
      let domainSingle = domainIsArray ? domain[0] : domain;
      if (!result) result = data[domainSingle];
      break;
    } else if (character in domain) {
      domain = domain[character];
    } else {
      return [];
    }
  }

  if (result) {
    domain = Array.isArray(domain) ? domain : [domain];
    let out = [];
    for (const v of domain) {
      const item = data[v];
      if (item.title.toLowerCase().slice(0, search.length) === search) out.push(item);
    }
    return out;
  }

  switch (typeof domain) {
    case 'undefined':
      return [];
    case 'number':
      return [data[domain]];
    case 'object':
      if (Array.isArray(domain)) {
        return domain.map(v => data[v]);
      }
      //eslint-disable-next-line no-fallthrough
    default:
      return getNestedValues(domain).map(v => data[v]);
  }
};

export const getAbilityDetailsById = getEntityDetailsById('abilities');
export const getAbilityDetailsByTitle = getEntityDetailsByTitle('abilities');

export const getPathDetailsById = getEntityDetailsById('paths');
export const getPathDetailsByTitle = getEntityDetailsByTitle('paths');

export const getRoleDetailsById = getEntityDetailsById('roles');
export const getRoleDetailsByTitle = getEntityDetailsByTitle('roles');