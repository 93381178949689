import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled/macro';
import theme from '../../theme';

import { getAbilityDetailsById, getRoleDetailsById } from '../../data/searchFunctions';
import AbilityDetails from './AbilityDetails';
import AbilityHeader from './AbilityHeader';

import { LockClosed, LockOpen } from '@emotion-icons/heroicons-solid';
import { ChevronDown, ChevronUp } from '@emotion-icons/heroicons-outline';

const PathAbility = styled.div`
  border-bottom: 1px solid ${theme.colors.label};
  margin-bottom: 0.25rem;

  --color: ${props => props.locked ? '#6c6a6a' : theme.colors.text};
  --role-underline-opacity: ${props => props.locked ? 'calc(200% / 3)' : '100%'};
  color: var(--color, ${theme.colors.text});
  padding-bottom: 0.2rem;

  &:last-of-type {
    border: none;
    margin-bottom: -0.25rem;
  }

  & > div:last-of-type {
    margin-bottom: 0.25rem;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: baseline;
  font-family: ${theme.fonts.heading};
  font-weight: ${theme.fontWeights.button};
  font-size: ${theme.fontSizes[3]};
  line-height: 1.75;
  text-transform: lowercase;
  cursor: pointer;
  vertical-align: baseline;
  user-select: none;
  word-break: break-all;

  &:last-of-type {
    border-bottom: none;
  }
`;

const UnlockButton = styled.div`
  display: inline-block;
  &:hover, ${TitleRow}:hover & {
    color: ${theme.colors.text};
  }
`;

const LockIcon = styled.div`
  display: inline-block;
  margin-right: 0.35em;

  &>svg {
    width: 15px;
    height: 15px;
    vertical-align: -1.5px;
  }
  
  &>svg:first-of-type {
    display: inline-block;
  }

  &>svg:last-of-type {
    display: none;
  }

  ${UnlockButton}:active &>svg:last-of-type {
    display: inline-block;
  }
  
  ${UnlockButton}:active &>svg:first-of-type {
    display: none;
  }
`;

const LockSymbol = () => {
  return (
    <LockIcon>
      <LockClosed size="15"/>
      <LockOpen size="15"/>
    </LockIcon>
  )
}

const CollapseSymbol = styled.div`
  margin-left: auto;
`;

const CollapseIndicator = ({abilityTitle, expanded, onKeyPress}) => {
  return (
    <CollapseSymbol tabIndex='0' onKeyPress={onKeyPress} role="button" title={`Click to ${expanded ? 'collapse' : 'expand'}`}>
      {expanded ? <ChevronUp size="18"/> : <ChevronDown size="18"/>}
    </CollapseSymbol>
  )
}

const AbilityDetailsContainer = styled.div`
  display: none;

  &.visible {
    display: block;
  }
`;

// Allows me to reverse the elements in the DOM without changing them visually.
// I was concerned that if a screen reader encountered a row in DOM-order,
// it would read "...Click to expand, Click to unlock, Wild Attack",
// which makes the ownership of the unlock button unclear.
// I haven't tested it yet, but I believe this should fix that issue.
// const ReverseDOMOrder = styled.div`
//   display: inline-flex;
//   flex-direction: row-reverse;
// `;

const ScreenReaderOnly = styled.div`
  width: 0;
  height: 0;
  overflow: hidden;
`;

//eslint-disable-next-line import/no-anonymous-default-export
export default ({data, locked, update, setParentFeedback}) => {
  const [ isExpanded, setIsExpanded ] = useState(!locked);

  const [abilityData, roleName] = useMemo(() => {
    const ability = getAbilityDetailsById(data);
    const role = getRoleDetailsById(ability.meta.role);
    return [ability, role.title];
  }, [data]);

  const handleExpandItem = (e) => {
    setIsExpanded(expanded => {
      setParentFeedback(abilityData.title, !expanded);
      return !expanded;
    });
    e.preventDefault();
    e.stopPropagation();
  }

  const handleUpdate = (e) => {
    update();
    e.stopPropagation();
    e.preventDefault();
  }

  const handleEnter = (handler) => (e) => {
    if (e.key === 'Enter') {
      handler(e);
    }
  }

  return (
    <PathAbility role="listitem" aria-label={abilityData.title} locked={locked}>
      <TitleRow expanded={isExpanded} onClick={handleExpandItem}>
        <UnlockButton role="button presentation" tabIndex={locked ? '0' : '-1'} aria-label={`${abilityData.title}; locked. Click to unlock.`} title="Click to unlock" onKeyPress={handleEnter(handleUpdate)} onClick={handleUpdate}>{locked && <LockSymbol/>}</UnlockButton>
        {isExpanded ? <AbilityHeader role={roleName}>{abilityData.title}</AbilityHeader> : <>{abilityData.title}</>}
        <CollapseIndicator abilityTitle={abilityData.title} role="button" tabIndex={0} expanded={isExpanded} onKeyPress={handleEnter(handleExpandItem)} onClick={handleExpandItem}/>
      </TitleRow>
      <AbilityDetailsContainer tabIndex="0" className={isExpanded ? 'visible' : ''}>
        <ScreenReaderOnly>{abilityData.title}.</ScreenReaderOnly>
        {(
          abilityData.data.map((v, i) => <AbilityDetails key={i} data={v}/>)
        )}
      </AbilityDetailsContainer>
    </PathAbility>
  )
};