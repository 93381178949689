import React from 'react';
import styled from '@emotion/styled/macro';
import theme from '../theme';

const FormInput = styled.div`
  --margin-size: 0.25em;
  display: inline-flex;
  position: relative;
  flex: 1 1 ${({size}) => size || 'auto'};
  line-height: initial;
  margin: 0 ${({margins}) => margins ? 'var(--margin-size)' : '0'} 2px;
  margin-left: ${({margins}) => (margins >> 1) & 1 ? 'var(--margin-size)' : '0'};
  margin-right: ${({margins}) => margins & 1 ? 'var(--margin-size)' : '0'};

  .header & {
    --margin-size: calc(0.75em / 2);
  }

  .grid-1-1 & {
    grid-row: 1;
    grid-column: 1;
  }

  &:after {
    position: absolute;
    display: block;
    content: '';
    height: 2px;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${theme.colors.text};
  }

  &:focus-within:after {
    background: ${theme.colors.link};
  }

  @media (max-width: ${theme.breakpoints[0]}) {
    margin: 0 0.25em;
    margin: 0 ${({margins}) => margins ? 'var(--margin-size)' : '0'} 2px;
    margin-left: ${({margins}) => (margins >> 3) & 1 ? 'var(--margin-size)' : '0'};
    margin-right: ${({margins}) => (margins >> 2) & 1 ? 'var(--margin-size)' : '0'};
    flex: 1 1 auto;
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  border: none;
  position: relative;
  outline: none;
  background: none;
  color: ${theme.colors.text};
  font-family: inherit;
  font-size: inherit;
  width: 100%;
  padding: 1px 2px;
  text-align: left;
  z-index: 1;
  text-overflow: ellipsis;
  white-space: pre;

  .header & {
    text-align: center;
  }

  &::placeholder {
    color: ${theme.colors.label};
  }
`;

const Label = styled.div`
  position: absolute;
  bottom: 0rem;
  left: 0;
  right: 0;
  height: 0;
  text-align: center;
  color: ${theme.colors.label};
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[1]};
  text-transform: lowercase;

  .header & {
    color: ${theme.colors.text};
  }
`;

const ScreenReaderOnly = styled.div`
  width: 0;
  height: 0;
  overflow: hidden;
`;

// margins:
// 0b{w}{x}{y}{z}
// [w,x,y,z] = [mobile-left, mobile-right, desktop-left, desktop-right]

//eslint-disable-next-line import/no-anonymous-default-export
export default ({ariaLabel, label, index, size, margins, placeholder, value, onChange, onKeyDown, onBlur}) => {
  if (typeof margins === "undefined") margins = 3;

  return (<>
    <FormInput size={size} margins={margins}>
      <Input aria-label={ariaLabel || label} index={index} placeholder={placeholder || ""} value={value} onChange={onChange} onKeyDown={onKeyDown} onBlur={onBlur}/>
      <Label aria-hidden="true">{label}</Label>
      <ScreenReaderOnly>{!value ? `${ariaLabel || label}` : ''}</ScreenReaderOnly>
    </FormInput>
  </>);
}