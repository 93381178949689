import styled from '@emotion/styled/macro';
import theme from '../theme';

const SectionHeader = styled.div`
  font-family: ${theme.fonts.heading};
  font-weight: ${theme.fontWeights.heading};
  font-size: ${theme.fontSizes[3]};
  color: ${theme.colors.text};
  text-transform: lowercase;
`;

//eslint-disable-next-line import/no-anonymous-default-export
export default ({children}) => <SectionHeader tabIndex="0" role="heading">{children}</SectionHeader>;