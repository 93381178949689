import styled from '@emotion/styled/macro';
import theme from '../../theme';

import { getAbilityDetailsById, getPathDetailsById, getRoleDetailsById } from '../../data/searchFunctions';
import React, { useMemo } from 'react';

const SuggestionItem = styled.div`
  position: relative;
  padding: 0.75rem 1.35rem;
  padding-right: 0.75rem;
  cursor: pointer;

  background: ${props => props.selected ? 'rgb(248, 248, 248)' : ''};

  &:hover {
    background: rgb(248, 248, 248) !important;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.25rem;
    background: ${props => props.selected ? theme.colors.link : 'transparent'};
    border-radius: 0 0.5rem 0.5rem 0;
  }
`;

const PathHeader = styled.div`
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[3]};
  font-weight: 600;
  text-transform: lowercase;
  color: ${props => theme.colors.roles[props.roleName.toLowerCase()]};
  line-height: 1;
  margin-bottom: 0.25rem;
`;

const PathAbilities = styled.div`
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[2]};
  font-weight: 400;
  text-transform: lowercase;
  color: ${theme.colors.text};
  font-size: 16px;
  letter-spacing: 0.2px;
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: normal;

  & strong {
    font-weight: 600;
    font-size: 15px;
  }
`

const LegendaryIcon = styled.span`
  font-size: 18px;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({data, selected, query, onClick}) => useMemo(() => {
  const matchType = data.hasOwnProperty('abilities') ? 'path' : 'ability';
  const roleName = getRoleDetailsById(data.meta.role).title;

  if (matchType === 'path') {
    return (
      <SuggestionItem selected={selected} onClick={() => onClick(data.id)}>
        <PathHeader roleName={roleName}>{data.title}</PathHeader>
        <PathAbilities>
          {data.abilities.map((ability, j) => <React.Fragment key={j}>{getAbilityDetailsById(ability).title}{j<data.abilities.length-1 ? ' \u2014 ' : ''}</React.Fragment>)}
        </PathAbilities>
      </SuggestionItem>
    );
  } else if (data.meta.path !== null) {
    const path = getPathDetailsById(data.meta.path);
    const roleName = getRoleDetailsById(path.meta.role).title;
    return (
      <SuggestionItem selected={selected} onClick={() => onClick(path.id)}>
        <PathHeader roleName={roleName}>{path.title}</PathHeader>
        <PathAbilities>
          {
            path.abilities.map((ability, j) => {
              const details = getAbilityDetailsById(ability);
              const formattedTitle = details.title === data.title ? <><strong>{query}</strong>{details.title.slice(query.length)}</> : <>{details.title}</>;
              return <React.Fragment key={j}>{formattedTitle}{j<path.abilities.length-1 ? ' \u2014 ' : ''}</React.Fragment>;
            })
          }
        </PathAbilities>
      </SuggestionItem>
    );
  } else {
    return (
      <SuggestionItem selected={selected} onClick={() => onClick(data.id)}>
        <PathHeader roleName={roleName}>{data.title} <LegendaryIcon>&#9733;&#xFE0F;</LegendaryIcon></PathHeader>
        <PathAbilities>
          Legendary ability
        </PathAbilities>
      </SuggestionItem>
    );
  }
}, [data, selected, query, onClick]);