import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import styled from '@emotion/styled/macro';
import theme from '../theme';
import version from '../data/version';
import { storage } from './store';

import NotFound from '../common/NotFound';
import Characters from '../features/characters/Characters';

import Layout from '../common/Layout';
import Button from '../common/Button';
import MenuButton from '../common/MenuButton';

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  overflow-y: auto;
`;

const ModalElement = styled.div`
  padding: 3rem;
  background: white;
  border: 1px solid ${theme.colors.border};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 54rem;
  max-width: 100%;

  & h1 {
    font-family: ${theme.fonts.pullquote};
    font-weight: ${theme.fontWeights.pullquote};
    margin-top: 0;
    flex-grow: 0;
    word-break: break-word;
    line-height: 1;
  }

  @media (max-width: ${theme.breakpoints[0]}) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overscroll-behavior: contain;
  }
`;

const ModalBody = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  margin-bottom: 3rem;
  font-size: 23px;

  & ul {
    margin: 0;

    li {
      margin-bottom: 0.75rem;
    }
  }

  @media (max-width: ${theme.breakpoints[0]}) {
    padding: 0 3rem;
    margin: 0 -3rem 3rem;
  }
`;

const PathTitleSpan = styled.span`
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[4]};
  font-weight: 600;
  text-transform: lowercase;
  color: ${props => props.roleName ? theme.colors.roles[props.roleName.toLowerCase()] : 'inherit'};
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const App = () => {
  const { pathname } = useLocation();
  const [hasClosedModal, setHasClosedModal] = useState(true);
  const [modalCloseData, setModalCloseData] = useState({});

  useEffect(() => {
    storage.getItem('hasClosedUpdateModal').then(versions => {
      setModalCloseData(versions || {});
      if (versions) {
        if (!versions[version]) {
          setHasClosedModal(false);
        }
      } else {
        setHasClosedModal(false);
      }
    });
  }, []);

  const handleModalClosed = () => {
    setHasClosedModal(true);
    storage.setItem('hasClosedUpdateModal', {
      ...modalCloseData,
      [version]: true
    });
  }

  return (
    <>
      <Layout>
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)}/>
          <Route path="/characters">
            <Characters/>
          </Route>
          <Route exact path="/">
            <Redirect to="/characters"/>
          </Route>
          <Route path="*">
            <MenuButton/>
            <NotFound>Not found</NotFound>
          </Route>
        </Switch>
      </Layout>
      { !hasClosedModal && (
        <ModalBackdrop>
          <ModalElement>
            <h1>Changelog</h1>
            <ModalBody>
              <ul>
                <li>Fixed an issue where path search would omit identically-named paths (looking at you, <PathTitleSpan roleName="magician">Conjuration</PathTitleSpan> and <PathTitleSpan roleName="wizard">Conjuration</PathTitleSpan>).</li>
                <li>Added the ability to search for paths by the abilities they contain.</li>
                <li>Added support for <PathTitleSpan>Legendary &#9733;&#xFE0F;</PathTitleSpan> abilities on the paths page!</li>
                <li>Improved interface and refined mobile layout.</li>
                <li>Enhanced accessibility for users with screen readers.</li>
              </ul>
            </ModalBody>
            <ModalButtons>
              <Button raised onClick={handleModalClosed}>Thank you! :)</Button>
            </ModalButtons>
          </ModalElement>
        </ModalBackdrop>
      ) }
    </>
  );
}

export default App;
