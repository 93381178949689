import { useEffect } from 'react';

const appName = 'Quest App';

export const useTitle = () => {
  useEffect(() => {
    document.title = appName;
  });
}

export const usePageTitle = (page) => {
  useEffect(() => {
    document.title = `${page} - ${appName}`
  });
};

export const useCharacterPageTitle = (name, page) => {
  useEffect(() => {
    document.title = `${name ? `${name}\u2019s` : `Character`} ${page} - ${appName}`;
  });
};