import React from 'react';
import styled from '@emotion/styled/macro';
import theme from '../theme';

const FormLine = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1.125rem;
  display: flex;
  line-height: ${theme.fontSizes[6]};
  align-items: flex-end;
  white-space: nowrap;

  @media (max-width: ${theme.breakpoints[0]}) {
    display: block;
  }

  &.header {
    margin-bottom: 3rem;
  }

  &.search {
    margin-bottom: 3rem;
  }

  :last-of-type {
    margin-bottom: 0;
  }
`;

const StepNumber = styled.div`
  position: absolute;
  left: 0;
  top: calc((${theme.numberSizes.line} / 2) - 2.5px);
  margin-top: calc((-1 * ${theme.numberSizes.line} / 2));
  margin-left: calc((-1 * ${theme.numberSizes.line} / 2) - ${theme.margins[1]} - 2.5px);
  height: ${theme.numberSizes.line};
  width: ${theme.numberSizes.line};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 50%;
  border: 5px solid ${theme.colors.border};
  background: ${theme.colors.border};
  color: ${theme.colors.background};
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[5]};
  font-weight: ${theme.fontWeights.heading};

  @media (max-width: ${theme.breakpoints[0]}) {
    margin-left: calc(-1.125rem - 2rem - 2.5px);
  }
`;

//eslint-disable-next-line import/no-anonymous-default-export
export default ({step, className, children, excludeFromTabOrder}) => {
  let addProps = {tabIndex: '0'};
  if (excludeFromTabOrder) {
    addProps = {};
  }
  return (
    <FormLine role="row" {...addProps} className={className}>
      { step && <StepNumber aria-hidden="true">{step}</StepNumber> }
      { children }
    </FormLine>
  );
}