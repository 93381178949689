import { characterActions } from '../features/characters/charactersSlice';

import store from '../app/store';

const sliceSetters = {
  'character': characterActions.setCharacterFieldById
};

const handleFieldChangeById = (sliceName) => (id) => (field) => (e) => {
  store.dispatch(
    sliceSetters[sliceName]({
      id, field, 
      value: e.target.value
    })
  )
};

const setFieldById = (sliceName) => (id) => (field) => (value) => {
  store.dispatch(
    sliceSetters[sliceName]({
      id, field, value
    })
  )
};

export const handleCharacterFieldChangeById = handleFieldChangeById('character');
// export const handleWorldFieldChangeById = handleFieldChangeById('world');
// export const handleCampaignFieldChangeById = handleFieldChangeById('campaign');

export const setCharacterFieldById = setFieldById('character');
// export const setWorldFieldById = setFieldById('world');
// export const setCampaignFieldById = setFieldById('campaign');