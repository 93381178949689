import styled from '@emotion/styled/macro';
import theme from '../theme';

import outerLeft from '../outer_left.svg';
import outerRight from '../outer_right.svg';
import innerLeft from '../inner_left.svg';
import innerRight from '../inner_right.svg';

import { useEffect, useRef } from 'react';

const FormWrapper = styled.div`
  position: relative;
  display: inline-block;
  max-width: 100%;
  min-width: auto;
  width: ${theme.pageWidth};
  border: 5px solid ${theme.colors.border};
  border-radius: ${theme.radii.page};
  box-sizing: border-box;
  padding: ${theme.margins[1]};
  /* margin: ${theme.formMargins}; */

  @media (max-width: ${theme.breakpoints[0]}) {
    padding: 2rem;
  }
`;

const FormTitle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: calc(3.5px - ${theme.margins[0]});
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${theme.fonts.heading};
  font-weight: ${theme.fontWeights.heading};
  font-size: ${theme.fontSizes[3]};
  color: ${theme.colors.background};
  text-transform: lowercase;
  outline: none;
`;

const Banner = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  justify-content: stretch;
  box-sizing: border-box;
  height: 41px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: 'banner';
`;

const BannerBorder = styled.div`
  grid-area: banner;
  height: 100%;
  display: flex;

  & div {
    width: calc(100% - 120px);
    background: ${theme.colors.background};
  }

  &::before, &::after {
    display: inline-block;
    content: '';
    width: 60px;
    height: 100%;
    background: ${theme.colors.background};
  }

  &::before {
    mask: url(${outerLeft});
  }

  &::after {
    mask: url(${outerRight});
  }
`;

const BannerInlay = styled.div`
  grid-area: banner;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  box-sizing: border-box;
  padding: 3px;

  & div {
    display: flex;
    width: calc(100% - 120px);
    height: 100%;
    background: ${theme.colors.border};
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 2;
    margin: 0 -4px;
    padding: 0 4px;
  }

  &::before, &::after {
    display: inline-block;
    content: '';
    width: 57px;
    height: 100%;
    background: ${theme.colors.border};
    mask-size: 57px 100%;
  }

  &::before {
    mask-image: url(${innerLeft});
  }

  &::after {
    mask-image: url(${innerRight});
  }
`;

//eslint-disable-next-line import/no-anonymous-default-export
export default ({title, className, children}) => {
  const titleRef = useRef();
  
  useEffect(() => {
    titleRef.current.focus();
  }, []);

  return (
    <FormWrapper className={className}>
      <FormTitle ref={titleRef} tabIndex="0" role="heading" aria-level="1">
        <Banner>
          <BannerBorder>
            <div/>
          </BannerBorder>
          <BannerInlay>
            <div>
              {title}
            </div>
          </BannerInlay>
        </Banner>
      </FormTitle>
      {children}
    </FormWrapper>
  )
}