import React, { useMemo } from 'react';
import styled from '@emotion/styled/macro';
import theme from '../../theme';

import AbilityDetails from './AbilityDetails';

import { getAbilityDetailsById, getRoleDetailsById } from '../../data/searchFunctions';
import PathMenu from './PathMenu';

const Legendary = styled.div`
  position: relative;
  border: 1px solid ${theme.colors.border};
  border-left: 4px solid ${props => theme.colors.roles[props.roleName.toLowerCase()]};
  margin-bottom: 0.5rem;
  padding: 1.25rem;
  padding-left: calc(1.25rem + 4px);
  grid-column: 1 / 3;

  &:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0px;
    left: -1px;
  };

  & > div:first-of-type {
    margin-bottom: 1.25rem;
  }
`;

const LegendaryHeader = styled.div`
  display: inline-block;
  position: relative;
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[4]};
  font-weight: 600;
  text-transform: lowercase;
  color: ${props => theme.colors.roles[props.roleName.toLowerCase()]};
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1;

  &:before {
    position: absolute;
    content: "";
    height: 2px;
    bottom: 0px;
    left: 0;
    right: 0;
  }
`;

const RoleHeader = styled.div`
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[3]};
  font-weight: 400;
  text-transform: lowercase;
  color: ${theme.colors.bullet};
  margin-top: -0.1em;
`;

const LegendaryTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  & > div:first-of-type {
    overflow: hidden;
  }
`;

const LegendaryBadge = styled.span`
  font-size: 23px;
`;

const LegendaryDetails = styled.div`
  margin-top: 2rem;
`;

//eslint-disable-next-line import/no-anonymous-default-export
export default ({abilityData, remove}) => {
  const [details, roleName] = useMemo(() => {
    const ability = getAbilityDetailsById(abilityData.id);
    const role = getRoleDetailsById(ability.meta.role);
    return [ability, role.title];
  }, [abilityData.id]);

  return (
    <Legendary className="path" role="listitem alert" roleName={roleName}>
      <LegendaryTitle>
        <div tabIndex="0">
          <LegendaryHeader roleName={roleName}>
            {details.title} <LegendaryBadge>&#9733;&#xFE0F;</LegendaryBadge>
          </LegendaryHeader>
          <RoleHeader>
            {roleName}
          </RoleHeader>
        </div>
        <PathMenu onRemove={remove} legendary={true}/>
      </LegendaryTitle>
      <LegendaryDetails>
        {
          details.data.map((v, i) => (
            <AbilityDetails key={i} data={v}/>
          ))
        }
      </LegendaryDetails>
    </Legendary>
  )
}