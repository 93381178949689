import React from 'react';
import styled from '@emotion/styled/macro';
import theme from '../../theme';

const AbilityHeader = styled.div`
  margin-top: calc(1.5rem - 3px);
  margin-bottom: 1rem;

  .path & {
    margin-top: 0rem;
    margin-bottom: 0.75rem;
  }
`;

const Header = styled.div`
  display: inline-block;
  position: relative;
  font-family: ${theme.fonts.heading};
  font-weight: ${theme.fontWeights.button};
  font-size: ${theme.fontSizes[3]};
  /*color: ${theme.colors.text};*/
  text-transform: lowercase;
  line-height: normal;

  &:after {
    position: absolute;
    display: block;
    content: '';
    height: 2px;
    left: 0;
    right: 0;
    bottom: -3px;
    background: ${theme.colors.text};
    background: ${props => theme.colors.roles[props.roleName.toLowerCase()]};
    opacity: var(--role-underline-opacity, 100%);
  }
`;

//eslint-disable-next-line import/no-anonymous-default-export
export default ({role, children}) => (
  <AbilityHeader>
    <Header roleName={role}>
      {children}
    </Header>
  </AbilityHeader>
);