import React from 'react';
import styled from '@emotion/styled/macro';
import theme from '../../theme';

import FormInput from '../../common/FormInput';

const CharacterBar = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 3rem;
  display: flex;
  line-height: ${theme.fontSizes[6]};
  align-items: flex-end;
  white-space: nowrap;

  @media (max-width: ${theme.breakpoints[0]}) {
    display: grid;
    grid-row-gap: 1.75rem;
  }

  :last-of-type {
    margin-bottom: 0;
  }
`;

//eslint-disable-next-line import/no-anonymous-default-export
export default ({character, onChange}) => {
  return (
    <CharacterBar className="header">
      <FormInput label="Hit Points" size="3em" margins={1} value={character.points_health} onChange={onChange('points_health')}/>
      <FormInput label="Character Name" size="15em" margins={3} value={character.basic_name} onChange={onChange('basic_name')}/>
      <FormInput label="AP" size="3em" margins={2} value={character.points_action} onChange={onChange('points_action')}/>
    </CharacterBar>
  )
}