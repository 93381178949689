import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import theme from '../../theme';

import MenuButton from '../../common/MenuButton';

const CharacterNavLayout = styled.div`
  width: 100%;
  height: 100%;
`;

const SectionNav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  transition: left 0.25s ease-out;
  right: 0;
  height: 4rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr minmax(auto, calc(100% - 8rem)) 1fr;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.text};
  color: rgb(237, 237, 237);
  z-index: 10;
  font-size: ${theme.fontSizes[3]};

  .menu-showing & {
    left: ${theme.dimensions.leftNav};
  }

  @media (max-width: ${theme.breakpoints[0]}) {
    transition: none;
    grid-template-columns: 1fr minmax(auto, calc(100% - 8rem)) 1fr;

    &:nth-of-type(2) {
      grid-column: 2 / 4;
    }

    .menu-showing & {
      left: 0;
    }
  }
`;

const SectionContent = styled.div`
  grid-area: content;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 4rem;
  padding: ${theme.formMargins};
  /* min-height: calc(100% - 7rem); */
`;

const NavScroller = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-x: overlay;

  @media (max-width: 36rem) {
    grid-column: 2 / 4 !important;
  }

  .menu-showing & {
    grid-column: 1 / 4;
  }
`;

const LinkList = styled.div`
  display: grid;
  grid-template-rows: 4rem;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  margin: 0 auto;
  height: 4rem;
  align-items: center;
  font-family: ${theme.fonts.heading}; 
  text-transform: lowercase;
  font-weight: 500;
  color: ${theme.colors.bullet};
  
  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.background} !important;
    height: 100%;
    padding: 0 2rem;
    text-overflow: ellipsis;
  }
`;

const MenuSpacer = styled.div`
  width: 4rem;
  
  .menu-showing & {
    display: none;
  }
`;

//eslint-disable-next-line import/no-anonymous-default-export
export default ({children}) => {
  return (
    <CharacterNavLayout>
      <SectionNav>
        <MenuSpacer>
          <MenuButton/>
        </MenuSpacer>
        <NavScroller>
          <LinkList role="banner tablist">
            <NavLink role="tab" to="profile">Profile</NavLink>
            <NavLink role="tab" to="inventory">Inventory</NavLink>
            <NavLink role="tab" to="paths">Paths</NavLink>
          </LinkList>
        </NavScroller>
      </SectionNav>
      <SectionContent role="tabpanel">
        {children}
      </SectionContent>
    </CharacterNavLayout>
  );
};