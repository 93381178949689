import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled/macro';
import theme from '../../theme';

import { DotsVertical } from '@emotion-icons/heroicons-outline';
import { Trash, Refresh } from '@emotion-icons/heroicons-solid';

const PathMenuButton = styled.div`
  cursor: pointer;

  & svg {
    pointer-events: none;
  }
`;

const PathMenuItem = styled.div`
  padding: 0.67rem 0.8rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover, &:focus {
    background: rgb(248, 248, 248);
  }

  & svg {
    margin-bottom: 1px;
    /* color: ${theme.colors.text}; */
    color: #423e3e;
    margin-left: 1rem;
  }
`;

const PathMenuItems = styled.div`
  position: absolute;
  display: none;
  justify-content: stretch;
  top: 0px;
  right: 26px;
  background: ${theme.colors.background};
  font-family: ${theme.fonts.heading};
  font-size: 1.1rem;
  color: ${theme.colors.text};
  font-weight: 500;
  text-transform: lowercase;
  box-shadow: -1px 1px 4px 0px rgba(0,0,0,0.3);
  user-select: none;
  white-space: nowrap;
  border: 1px solid ${theme.colors.label};
  grid-auto-flow: row;
  z-index: 9;
  outline: none;

  .menu-open & {
    display: grid;
  }
`;

const PathMenu = styled.div`
  position: relative;
  overflow: visible;
`;

//eslint-disable-next-line import/no-anonymous-default-export
export default ({onReset, onRemove, legendary}) => {
  const [showMenu, setShowMenu] = useState(false);

  const menuRef = useRef(null);
  const menuItemsRef = useRef(null);

  const handleClick = (e) => {
    setShowMenu(prev => {
      const show = !prev;
      return show;
    });
  }

  const handleDismiss = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setShowMenu(false);
      const focusedChild = menuRef.current.parentElement.querySelector(":focus");
      if (focusedChild) focusedChild.blur();
    }
  }
  
  useEffect(() => {
    if (showMenu) {
      window.addEventListener('mousedown', handleDismiss);
      menuItemsRef.current.focus();
    } else {
      window.removeEventListener('mousedown', handleDismiss);
    }
  }, [showMenu]);

  const handleReset = (e) => {
    if (onReset) onReset(e);
    setShowMenu(false);
  }

  const handleRemove = (e) => {
    if (onRemove) onRemove(e);
    setShowMenu(false);
  }

  const handleEnter = (handler) => (e) => {
    if (e.key === 'Enter') {
      if (handler) handler(e);
    }
  }

  return (
    <PathMenu ref={menuRef} className={showMenu ? 'menu-open' : ''}>
      <PathMenuButton className="menu-button" tabIndex="0" aria-label="Options Menu Button" onMouseDown={handleClick} onKeyPress={handleEnter(handleClick)}>
        <DotsVertical className="menu-button" size="20"/>
      </PathMenuButton>
      <PathMenuItems ref={menuItemsRef} role="menu" tabIndex="-1" aria-label="Options">
        {
          typeof onReset !== 'undefined' &&
          <PathMenuItem role="menuitem" tabIndex="0" onClick={handleReset}>
            Reset progression&nbsp;
            <Refresh size="18"/>
          </PathMenuItem>
        }
        <PathMenuItem role="menuitem" tabIndex="0" onClick={handleRemove}>
          Remove {legendary ? 'ability' : 'path'}&nbsp;
          <Trash size="18"/>
        </PathMenuItem>
      </PathMenuItems>
    </PathMenu>
  );
};