import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FormWrapper from '../../common/FormWrapper';
import FormLine from '../../common/FormLine';
import CharacterBar from './CharacterBar';
import SectionHeader from '../../common/SectionHeader';
import PathAutoCompleteInput from './PathAutoCompleteInput';

import { selectIds, selectById } from './charactersSlice';
import { handleCharacterFieldChangeById } from '../../common/fieldFunctions';
import { setCharacterFieldById } from '../../common/fieldFunctions';

import { useCharacterPageTitle } from '../../common/usePageTitles';

import { getAbilityDetailsById, getAutoComplete, getPathDetailsById } from '../../data/searchFunctions';
import FormGrid from '../../common/FormGrid';
import Path from './Path';
import Legendary from './Legendary';
import QuickStartCard from './QuickStartCard';

const ScreenReaderOnly = styled.div`
  width: 0;
  height: 0;
  overflow: hidden;
`;

const EmptyStateContainer = styled.div`
  grid-column: 1 / span 2;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;

const withAutoComplete = (value) => {
  if (value !== "") {
    const pathsAutocomplete = getAutoComplete('paths')(value);
    const abilitiesAutocomplete = getAutoComplete('abilities')(value);
    return [...pathsAutocomplete, ...abilitiesAutocomplete];
  } else {
    return [];
  }
}

const CharacterPaths = () => {
  const index = parseInt(useParams().index);
  const id = useSelector(selectIds)[index];

  const [searchValue, setSearchValue] = useState("");
  const [matchIndex, setMatchIndex] = useState(0);

  const [assistiveFeedback, setAssistiveFeedback] = useState('');

  const handleFieldChange = handleCharacterFieldChangeById(id);
  const setCharacterPaths = setCharacterFieldById(id)('paths');

  const character = useSelector(state => selectById(state, id));
  useCharacterPageTitle(character.basic_name, 'Paths');

  const paths = character.paths;

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleIndexChange = (index) => {
    setMatchIndex(index);
  }
  
  const handleAddPath = (data) => {
    const pathDetails = getPathDetailsById(data.id);
    if (pathDetails) {
      if (!paths.some(path => path.id === pathDetails.id && path.type === 'path')) {
        const newPaths = paths.map(p => ({
          id: p.id,
          level: p.level,
          type: p.type || 'path',
        }));
        setCharacterPaths([...newPaths, {
          id: pathDetails.id,
          level: 0,
          type: 'path'
        }]);
        setAssistiveFeedback(`${data.title} path added.`);
      } else {
        setAssistiveFeedback(`${data.title} path has already been added to character.`);
      }
    } else {
      setAssistiveFeedback(`Unable to add path ${data.title}.`);
    }
  }

  const handleAddLegendary = (data) => {
    const abilityDetails = getAbilityDetailsById(data.id);
    if (abilityDetails) {
      if (!paths.some(path => path.id === abilityDetails.id && path.type === 'legendary')) {
        const newPaths = paths.map(p => ({
          id: p.id,
          level: p.level,
          type: p.type || 'path',
        }));
        setCharacterPaths([...newPaths, {
          id: abilityDetails.id,
          type: 'legendary'
        }]);
        setAssistiveFeedback(`${data.title} ability added.`);
      } else {
        setAssistiveFeedback(`${data.title} ability has already been added to character.`);
      }
    } else {
      setAssistiveFeedback(`Unable to add ability ${data.title}.`);
    }
  }

  const handleUpdatePathLevel = (index) => (level) => {
    if (paths.length > index) {
      let temp = [];
      for (let path of paths) {
        temp.push({
          id: path.id,
          level: path.level,
          type: path.type
        });
      }
      temp[index].level = level;
      setCharacterPaths(temp);
    }
  };

  const handleRemovePath = (index) => () => {
    if (paths.length > index) {
      let old = paths[index];
      let temp = [...paths];
      temp.splice(index, 1);
      setCharacterPaths(temp);
      if (old.type === 'path') {
        setAssistiveFeedback(`${getPathDetailsById(old.id).title} path removed.`);
      } else if (old.type === 'legendary') {
        setAssistiveFeedback(`${getAbilityDetailsById(old.id).title} ability removed.`);
      }
    }
  }

  const suggestions = useMemo(() => withAutoComplete(searchValue), [searchValue]);

  const submitSearch = () => {
    if (suggestions && suggestions.length - 1 >= matchIndex && suggestions[matchIndex] !== undefined) {
      if (suggestions[matchIndex].hasOwnProperty("abilities")) {
        handleAddPath(suggestions[matchIndex]);
      } else {
        const path = getPathDetailsById(suggestions[matchIndex].meta.path);
        if (path !== null) {
          handleAddPath(path);
        } else if (suggestions[matchIndex].meta.path === null) {
          // console.log("Adding a legendary ability! ⭐");
          handleAddLegendary(suggestions[matchIndex]);
        } else {
          console.error("Unable to add path.");
        }
      }
      setSearchValue("");
      setMatchIndex(0);
    }
  }

  // Dev only
  // useEffect(() => {
  //   if (suggestions && suggestions.length-1 >= matchIndex) {
  //     console.log("Match index:", matchIndex);
  //     console.log("Match data:", suggestions?.[matchIndex]);
  //   }
  // }, [matchIndex, suggestions]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      submitSearch();
    }
  }

  const handleQuickStart = (pathIds) => {
    const paths = pathIds.map(id => ({
      id: id,
      level: 0,
      type: 'path',
    }));
    setCharacterPaths(paths);
  }

  const handleSuggestionClick = (id) => {
    const path = getPathDetailsById(id);

    if (path !== null) {
      handleAddPath(path);
    }

    setSearchValue("");
    setMatchIndex(0);
  }

  return (
    <FormWrapper title="Character Paths">
      <CharacterBar character={character} onChange={handleFieldChange}/>
      <FormLine excludeFromTabOrder>
        <SectionHeader>Learned Paths</SectionHeader>
      </FormLine>
      <FormLine className="search" excludeFromTabOrder>
        <PathAutoCompleteInput
          role="Search"
          aria-label="Search for paths. Press enter to add the path to the character."
          label="Search for paths"
          margins={0}
          placeholder="Search for paths..."
          suggestions={suggestions}
          value={searchValue}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          onIndexChange={handleIndexChange}
          onClickSuggestion={handleSuggestionClick}
        />
      </FormLine>
      <ScreenReaderOnly role="alert">{ assistiveFeedback }</ScreenReaderOnly>
      <FormGrid noMinHeight aria-label="Paths" role="list">
        {paths.map((data, key) => {
          return (
            data.type === 'legendary'
            ? <Legendary key={key} remove={handleRemovePath(key)} abilityData={data}/>
            : <Path key={key} update={handleUpdatePathLevel(key)} remove={handleRemovePath(key)} pathData={data}/>
          )
        })}
        {paths.length === 0 && <>
          <EmptyStateContainer>
            Choose a role below to begin, or add paths using the search box above.
          </EmptyStateContainer>
          {[0,2,3,4,5,6,7].map((v) => {
            return <QuickStartCard key={v} roleId={v} onSelect={handleQuickStart}/>
          })}
        </>}
      </FormGrid>
    </FormWrapper>
  );
};

export default CharacterPaths;