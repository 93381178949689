import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import styled from '@emotion/styled/macro';

import FormWrapper from '../../common/FormWrapper';
import FormRowLayout from '../../common/FormRowLayout';
import FormLine from '../../common/FormLine';
import FormInput from '../../common/FormInput';
import MobileLine from '../../common/MobileLine';

import { selectIds, selectById } from './charactersSlice';
import { handleCharacterFieldChangeById } from '../../common/fieldFunctions';

import { useCharacterPageTitle } from '../../common/usePageTitles';

const InlineBlock = styled.div`
  display: inline-block;
  white-space: break-spaces;
`;

const CharacterProfile = () => {
  const index = parseInt(useParams().index);
  const id = useSelector(selectIds)[index];
  
  const handleFieldChange = handleCharacterFieldChangeById(id);

  const character = useSelector(state => selectById(state, id));
  useCharacterPageTitle(character.basic_name, 'Profile');

  return (
    <FormWrapper hasStepNumbers={true} title="Character Profile">
      <FormRowLayout>
        <FormLine>Hello,</FormLine>
        <FormLine>
          <MobileLine>
            My name is
            <FormInput label="Name" margins={0b1011} value={character.basic_name} onChange={handleFieldChange('basic_name')}/>
          </MobileLine>
          <MobileLine>
            (<FormInput label="Pronouns" size='7em' margins={0b0000} value={character.basic_pronouns} onChange={handleFieldChange('basic_pronouns')}/>).
          </MobileLine>
        </FormLine>
        <FormLine>
          <MobileLine>
            I&rsquo;m
            <FormInput label="Age" size='5em' margins={0b1111} value={character.basic_age} onChange={handleFieldChange('basic_age')}/>
            years old{` `}
          </MobileLine>
          <MobileLine>
            and stand
            <FormInput label="Height" margins={0b1111} value={character.basic_height} onChange={handleFieldChange('basic_height')}/>
            tall.
          </MobileLine>
        </FormLine>
        <FormLine step="1">
          <MobileLine>
            I&rsquo;m the party&rsquo;s
            <FormInput label="Role" margins={0b1010} value={character.basic_role} onChange={handleFieldChange('basic_role')}/>
            .
          </MobileLine>
        </FormLine>
        <FormLine step="2">
          <InlineBlock>
            When people see me, they first notice my
          </InlineBlock>
        </FormLine>
        <FormLine>
          <MobileLine>
            <FormInput label="Body" margins={0b0000} value={character.feature_body} onChange={handleFieldChange('feature_body')}/>
            ,
          </MobileLine>
          <MobileLine>
            <FormInput label="Face" margins={0b0010} value={character.feature_face} onChange={handleFieldChange('feature_face')}/>
            ,
          </MobileLine>
        </FormLine>
        <FormLine>
          <MobileLine>
            and
            <FormInput label="Vibe" margins={0b1010} value={character.feature_vibe} onChange={handleFieldChange('feature_vibe')}/>
            .
          </MobileLine>
        </FormLine>
        <FormLine step="3">
          <MobileLine>
            I wear
            <FormInput label="Clothing 1" margins={0b1010} value={character.style_clothing_1} onChange={handleFieldChange('style_clothing_1')}/>
            ,
          </MobileLine>
        </FormLine>
        <FormLine>
          <MobileLine>
            <FormInput label="Clothing 2" margins={0b0000} value={character.style_clothing_2} onChange={handleFieldChange('style_clothing_2')}/>
            ,
          </MobileLine>
        </FormLine>
        <FormLine>
          <MobileLine>
            and move with
            <FormInput label="Movement" margins={0b1010} value={character.style_movement} onChange={handleFieldChange('style_movement')}/>
            .
          </MobileLine>
        </FormLine>
        <FormLine step="4">
          <MobileLine>
            I&rsquo;m from
            <FormInput label="Home" margins={0b1010} value={character.origin_home} onChange={handleFieldChange('origin_home')}/>
            ,
          </MobileLine>
        </FormLine>
        <FormLine>
          <InlineBlock>
            where my people are known for
          </InlineBlock>
        </FormLine>
        <FormLine>
          <MobileLine>
            <FormInput label="Community" margins={0b0000} value={character.origin_community} onChange={handleFieldChange('origin_community')}/>
            .
          </MobileLine>
        </FormLine>
        <FormLine step="5">
          <MobileLine>
            I believe in
            <FormInput label="Ideal" margins={0b1010} value={character.spirit_ideal} onChange={handleFieldChange('spirit_ideal')}/>
            ,
          </MobileLine>
        </FormLine>
        <FormLine step="6">
          <MobileLine>
            but my
            <FormInput label="Flaw" margins={0b1111} value={character.spirit_flaw} onChange={handleFieldChange('spirit_flaw')}/>
            side{` `}
          </MobileLine>
          <MobileLine shouldWrap>
            can get in my way.
          </MobileLine>
        </FormLine>
        <FormLine step="7">
          <MobileLine>
            I dream of
            <FormInput label="Dream" margins={0b1010} value={character.spirit_dream} onChange={handleFieldChange('spirit_dream')}/>
            .
          </MobileLine>
        </FormLine>
        <FormLine step="8">
          <InlineBlock>
            I carry...&nbsp;
            <span>
              (<Link to="./inventory">view character inventory</Link>)
            </span>
          </InlineBlock>
        </FormLine>
      </FormRowLayout>
    </FormWrapper>
  );
};

export default CharacterProfile;