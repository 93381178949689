import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled/macro';
import theme from '../../theme';

import PathAbility from './PathAbility';

import { getPathDetailsById, getAbilityDetailsById, getRoleDetailsById } from '../../data/searchFunctions';
import PathMenu from './PathMenu';

const Path = styled.div`
  position: relative;
  border: 1px solid ${theme.colors.border};
  border-left: 4px solid ${props => theme.colors.roles[props.roleName.toLowerCase()]};
  margin-bottom: 0.5rem;
  padding: 1.25rem;
  padding-left: calc(1.25rem + 4px);
  grid-column: 1 / 3;

  &:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0px;
    left: -1px;
  };

  & > div:first-of-type {
    margin-bottom: 1.25rem;
  }
`;

const PathHeader = styled.div`
  display: inline-block;
  position: relative;
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[4]};
  font-weight: 600;
  text-transform: lowercase;
  color: ${props => theme.colors.roles[props.roleName.toLowerCase()]};
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1;

  &:before {
    position: absolute;
    content: "";
    height: 2px;
    bottom: 0px;
    left: 0;
    right: 0;
  }
`;

const RoleHeader = styled.div`
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[3]};
  font-weight: 400;
  text-transform: lowercase;
  color: ${theme.colors.bullet};
  margin-top: -0.1em;
`;

const PathTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  & > div:first-of-type {
    overflow: hidden;
  }
`;

const ScreenReaderOnly = styled.div`
  width: 0;
  height: 0;
  overflow: hidden;
`;

//eslint-disable-next-line import/no-anonymous-default-export
export default ({pathData, update, remove}) => {
  const [ assistiveFeedback, setAssistiveFeedback ] = useState('');

  const [details, roleName] = useMemo(() => {
    const path = getPathDetailsById(pathData.id);
    const role = getRoleDetailsById(path.meta.role);
    return [path, role.title];
  }, [pathData.id]);

  const handleUpdate = (i) => () => {
    update(i);
    setAssistiveFeedback(`${getAbilityDetailsById(details.abilities[i]).title} unlocked.`);
  }

  const handleExpandCollapseFeedback = (title, expanded) => {
    setAssistiveFeedback(`${title} ${expanded ? 'expanded' : 'collapsed'}.`);
  }

  const handleReset = () => {
    update(0);
    setAssistiveFeedback(`${details.title} progression reset.`);
  }

  return (
    <Path className="path" role="listitem alert" roleName={roleName}>
      <PathTitle>
        <div tabIndex="0">
          <PathHeader roleName={roleName}>
            {details.title}
          </PathHeader>
          <RoleHeader>
            {roleName}
          </RoleHeader>
        </div>
        <PathMenu onReset={handleReset} onRemove={remove}/>
      </PathTitle>
      <ScreenReaderOnly role="alert">{ assistiveFeedback }</ScreenReaderOnly>
      <div>
        {details.abilities.map((v, i) => {
          const locked = (i > pathData.level);
          return (
            <PathAbility key={i} data={v} locked={locked} setParentFeedback={handleExpandCollapseFeedback} update={handleUpdate(i)}/>
          );
        })}
      </div>
    </Path>
  )
}