import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import NotFound from '../../common/NotFound';
import CharacterEditor from './CharacterEditor';
import CharacterSelect from './CharacterSelect';

const Characters = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/:index(\\d+)`}>
        <CharacterEditor/>
      </Route>
      <Route exact path={path}>
        {/* Load/create character screen */}
        <CharacterSelect/>
      </Route>
      <Route path="*">
        <NotFound>Not found</NotFound>
      </Route>
    </Switch>
  )
};

export default Characters;