import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled/macro';
import theme from '../../theme';

import Button from '../../common/Button';

import { saveAs } from 'file-saver';

import store from '../../app/store';
import { deleteCharacterById } from './charactersSlice';

import { selectIds } from './charactersSlice';
import version from '../../data/version';

const CharacterCard = styled.div`
  border: 1px solid ${theme.colors.border};
  padding: 1rem;
  color: ${theme.colors.text} !important;
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: stretch;
  grid-column-gap: 1rem;
  align-items: flex-start;
  max-width: 100%;
`;

const CharacterInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: inherit !important;
  box-sizing: border-box;
`;

const CharacterMenu = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-auto-flow: row;
  grid-row-gap: 0.5rem;
  flex-grow: 0;
`;

const CharacterName = styled.div``;

const CharacterRole = styled.div`
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[3]};
  font-weight: 500;
  text-transform: lowercase;
  color: ${theme.colors.text};
  color: ${props => theme.colors.roles[props.roleName.toLowerCase()]} !important;
`;

const generateSaveData = (character) => {
  return {
    meta: {
      id: character.meta_id,
      type: 'Character',
      version: version,
      updated: character.meta_updated
    },
    basic: {
      name: character.basic_name,
      pronouns: character.basic_pronouns,
      age: character.basic_age,
      height: character.basic_height,
      role: character.basic_role
    },
    features: {
      body: character.feature_body,
      face: character.feature_face,
      vibe: character.feature_vibe
    },
    style: {
      clothing_1: character.style_clothing_1,
      clothing_2: character.style_clothing_2,
      movement: character.style_movement
    },
    origin: {
      home: character.origin_home,
      community: character.origin_community
    },
    spirit: {
      ideal: character.spirit_ideal,
      flaw: character.spirit_flaw,
      dream: character.spirit_dream
    },
    points: {
      health: character.points_health,
      action: character.points_action
    },
    paths: character.paths,
    inventory: character.inventory
  };
};

//eslint-disable-next-line import/no-anonymous-default-export
export default ({character}) => {
  const index = useSelector(selectIds).indexOf(character.meta_id);

  if (index < 0) {
    return (
      <CharacterCard>
        <CharacterInfo>
          <CharacterName>Character Error</CharacterName>
          <CharacterRole>Not found</CharacterRole>
        </CharacterInfo>
      </CharacterCard>
    )
  };

  const handleExport = (e) => {
    e.preventDefault();
    const data = generateSaveData(character);
    const file = new File([JSON.stringify(data, null, 2)], `${character.basic_name||"unnamed"}.json`, {
      type: "application/json;charset=utf-8"
    });
    saveAs(file);
  }

  const handleDelete = (e) => {
    e.preventDefault();
    if (window.confirm(`Delete character ${character.basic_name||'unnnamed'}? This cannot be undone.`)) {
      store.dispatch(
        deleteCharacterById({
          id: character.meta_id
        })
      );
    }
  }

  return (
    <CharacterCard as={Link} to={`./characters/${index}`} tabIndex="0" role="listitem">
      <CharacterInfo>
        <CharacterName>{character.basic_name || "Unnamed Character"}</CharacterName>
        <CharacterRole roleName={character.basic_role}>{character.basic_role || "No role"}</CharacterRole>
      </CharacterInfo>
      <CharacterMenu role="menu">
        <Button role="menuitem button" compact onClick={handleExport}>Export</Button>
        <Button role="menuitem button" className="danger" compact onClick={handleDelete}>Delete</Button>
      </CharacterMenu>
    </CharacterCard>
  );
};