import styled from '@emotion/styled/macro';
import theme from '../../theme';

import { getRoleDetailsById } from '../../data/searchFunctions';

import Fighter from '../../fighter.svg';
import Invoker from '../../invoker.svg';
import Ranger from '../../ranger.svg';
import Naturalist from '../../naturalist.svg';
import Doctor from '../../doctor.svg';
import Spy from '../../spy.svg';
import Magician from '../../magician.svg';
import Wizard from '../../wizard.svg';

const descriptions = [
  'The Fighter takes charge to meet challenges up close. They are weapon masters and martial artists, relying on their physical might to overcome foes.',
  'The Invoker is a battle mage, relying on the force of their ideals. They conjure protective wards, invigorate allies in a pinch, and smite enemies with radiance.',
  'The Ranger is an outlander, hunter, and skilled navigator, thriving on the fringes of civilization. They keep faithful pets and have a special bond with beasts.',
  'The Naturalist channels their connection with nature to manipulate the elements, commune with animals, and even transform themselves into wild beasts.',
  'The Doctor is a magic scientist who tinkers with the forces of life and death. They reverse (or advance) the effects of damage, disease, and decay.',
  'The Spy is a crafty agent of stealth and subterfuge. They are master assassins and experts in the use of magical gadgets, chemicals, traps, disguises, and forgeries.',
  'The Magician specializes in conjuration and psychic manipulation. From parlor tricks to elaborate deceptions, they are master illusionists, capable of twisting the mind.',
  'The Wizard is a powerful spellcaster with a diverse set of magical abilities. At the height of their power, they can travel to other worlds and transcend their mortal selves.'
];

const icons = [ Fighter, Invoker, Ranger, Naturalist, Doctor, Spy, Magician, Wizard ];

const QuickStartCard = styled.div`
  position: relative;
  padding: 1.25rem;
  padding-left: calc(1.25rem + 4px);
  border: 1px solid ${theme.colors.border};
  border-left-width: 4px;
  border-left-color: ${props => theme.colors.roles[props.roleName.toLowerCase()]};
  /* border-left-color: ${theme.colors.text}; */
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  grid-column: 1 / span 2;
  cursor: pointer;

  &:hover {
    background: rgb(248, 248, 248);
  }
`;

const RoleHeader = styled.div`
  font-family: ${theme.fonts.heading};
  font-size: 24px;
  font-weight: 600;
  text-transform: lowercase;
  color: ${props => theme.colors.roles[props.roleName.toLowerCase()]};
  /* color: ${theme.colors.text}; */
  line-height: 1;
  margin-bottom: 0.25rem;
`;

const RoleDescription = styled.p`
  font-size: 15px;
`;

const RoleImage = styled.img`
  object-fit: contain;
  width: 7rem;
  height: 7rem;
  padding: 0.5rem;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({roleId, onSelect}) => {
  const data = getRoleDetailsById(roleId);
  return (
    <QuickStartCard tabIndex="0" roleName={data.title} onClick={() => onSelect(data.paths)}>
      <div>
        <RoleHeader roleName={data.title}>
          {data.title}
        </RoleHeader>
        <RoleDescription>
          {descriptions[roleId]}
        </RoleDescription>
      </div>
      <RoleImage alt={data.title} src={icons[roleId]}/>
    </QuickStartCard>
  )
};