import styled from '@emotion/styled/macro';
import theme from '../theme';

import { Menu as MenuIcon } from '@emotion-icons/heroicons-outline';

const MenuButton = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${theme.dimensions.topNav};
  height: ${theme.dimensions.topNav};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 13;
  pointer-events: none;
  color: ${props => props.fill ? props.fill : 'inherit'};
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  return (
    <MenuButton {...props}>
      <MenuIcon size="24"/>
    </MenuButton>
  );
}