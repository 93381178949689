import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FormWrapper from '../../common/FormWrapper';
import CharacterBar from './CharacterBar';
import FormLine from '../../common/FormLine';
import FormGrid from '../../common/FormGrid';
import FormTextArea from '../../common/FormTextArea';
import SectionHeader from '../../common/SectionHeader';

import { selectIds, selectById } from './charactersSlice';
import { handleCharacterFieldChangeById } from '../../common/fieldFunctions';
import { setCharacterFieldById } from '../../common/fieldFunctions';

import flattenRichText from '../../common/flattenRichText';

import { useCharacterPageTitle } from '../../common/usePageTitles';

const CharacterInventory = () => {
  const index = parseInt(useParams().index);
  const id = useSelector(selectIds)[index];

  const handleFieldChange = handleCharacterFieldChangeById(id);
  const setCharacterInventory = setCharacterFieldById(id)('inventory');

  const character = useSelector(state => selectById(state, id));
  useCharacterPageTitle(character.basic_name, 'Inventory');

  const inventory = character.inventory;

  const handleInventoryChange = (index) => (e) => {
    let clonedInventory = [...inventory];
    clonedInventory[index] = flattenRichText(e.target.value);
    setCharacterInventory(clonedInventory);
  };

  return (
    <FormWrapper title="Character Inventory">
      <CharacterBar character={character} onChange={handleFieldChange}/>
      <FormLine excludeFromTabOrder>
        <SectionHeader>Personal Inventory</SectionHeader>
      </FormLine>
      <FormGrid>
        {inventory.map((v,i) => {
          return <FormTextArea label={`Inventory ${i+1}`} key={i} number={i+1} value={v} onChange={handleInventoryChange(i)}/>
        })}
      </FormGrid>
    </FormWrapper>
  );
};

export default CharacterInventory;