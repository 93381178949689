import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import theme from '../theme';

import { usePageTitle } from './usePageTitles';
import Button from './Button';

const NotFound = styled.div`
  display: grid;
  justify-content: center;
  grid-row-gap: 48px;
  text-align: center;
  padding: ${props => props.condensed ? '2rem' : 'calc(64px + 1rem)'} 16px 0px;
`;

const Header = styled.h1`
  font-family: ${theme.fonts.pullquote};
  font-weight: ${theme.fontWeights.pullquote};
  font-size: ${theme.fontSizes[9]};
  margin: 0;
  line-height: 0.85;
`;

const RoleUnderline = styled.div`
  position: relative;
  display: inline-block;
  font-family: ${theme.fonts.heading};
  text-transform: lowercase;
  letter-spacing: -0.2px;
  font-weight: 400;
  line-height: 1.5;

  &:after {
    position: absolute;
    display: block;
    content: '';
    height: 3px;
    left: 0;
    right: 0;
    bottom: calc(1em / 6);
    background: inherit;
    background: ${props => theme.colors.roles[props.role.toLowerCase()]};
  }
`;

const AlignButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6px;
`;

const ScreenReaderOnly = styled.span`
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
`;

//eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const history = useHistory();
  
  usePageTitle('Not Found');

  const handleBackNav = (e) => {
    e.preventDefault();
    history.goBack();
  }

  const jokes = [
    <div>You cast <RoleUnderline role="wizard">Find</RoleUnderline> on the page, but it fails!</div>,
    <div>You try to cast <RoleUnderline role="invoker">Shadowseek</RoleUnderline>, but you trip and fall instead.</div>,
    <div>You cast <RoleUnderline role="ranger">Speak with Trees</RoleUnderline>, but the trees don't answer.</div>,
    <div>I wonder if <RoleUnderline role="spy">Seeker</RoleUnderline> could make me a sitemap...</div>
  ];

  const chosenJoke = jokes[Math.floor(Math.random() * jokes.length)];

  return (
    <NotFound role="alert" {...props}>
      <Header>Not found</Header>
      <span>
        <ScreenReaderOnly>Not Found.</ScreenReaderOnly>
        {chosenJoke}
      </span>
      <AlignButton>
        <Button onClick={handleBackNav}>Go back</Button>
      </AlignButton>
    </NotFound>
  )
};