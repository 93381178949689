import { v4 } from 'uuid';
import localForage from 'localforage';
import { configureStore } from '@reduxjs/toolkit';
import { BroadcastChannel as BroadcastChannelPolyfill } from 'broadcast-channel';

import charactersReducer, { loadCharactersFromStorage } from '../features/characters/charactersSlice';
// import { worldsSlice } from '../features/worlds/worldsSlice';
// import { campaignsSlice } from '../features/campaigns/campaignsSlice';

// Note: In order to translate indexes into IDs, you should be able to use store.getState().characters;
// It looks like the official method is: charactersSelectors.selectAll(store.getState())
// Does this work with useSelector?

// We're going to need a way to determine which tab was in charge of the update.
// This might be difficult given that we don't directly control the action factories.
// Can we get away with not having this at first, and just letting the tabs compete to store the data?

// Ah ha! Monkeypatch over the dispatch function a la https://redux.js.org/understanding/history-and-design/middleware#attempt-3-monkeypatching-dispatch
// Use it to add a key for the current tab id.

// Wait, wait. It looks like we can just create a middleware for this.

const tabId = v4();
let lastActionCalledBy = null;
let channel = {
  postMessage: () => {}
};

const supportsBroadcastChannel = !!window.BroadcastChannel;

if (supportsBroadcastChannel) {
  channel = new BroadcastChannel('quest_fan_sync');
} else {
  channel = new BroadcastChannelPolyfill('quest_fan_sync');
}

export const storage = localForage.createInstance({
  name: "quest_fan_storage"
});

channel.onmessage = (e) => {
  const data = (supportsBroadcastChannel ? e.data : e);
  if (data.from !== tabId) {
    const action = data.action;
    action.payload.isSync = true;
    store.dispatch(action);
  }
};

const assignCallerToAction = store => next => action => {
  // console.log('Assign:', action);
  if (action.hasOwnProperty('payload') && !action.payload.hasOwnProperty('caller')) {
    // Skip saving if we're loading the state from storage.
    if (action.type !== 'character/loadCharactersFromStorage') {
      action.payload.caller = tabId;
    } else {
      // console.log("Ignored tagging load action");
    }
  }
  // console.log('Payload:', action.payload);
  return next(action);
};

const sendSyncedAction = store => next => action => {
  if (action.payload.hasOwnProperty('isSync') && action.payload.isSync) {
    delete action.payload.isSync;
  } else if (action.payload.hasOwnProperty('caller')) {
    channel.postMessage({
      from: tabId,
      action: action
    });
  }
  return next(action);
}

const identifyActionCallerInScope = store => next => action => {
  // console.log('Identify:', action);
  if (action.hasOwnProperty('payload') && action.payload.hasOwnProperty('caller')) {
    lastActionCalledBy = action.payload.caller;
    delete action.payload.caller;
  } else {
    lastActionCalledBy = null;
  }
  return next(action);
};

const store = configureStore({
  reducer: {
    characters: charactersReducer,
    // worlds: worldsSlice.reducer,
    // campaigns: campaignsSlice.reducer
  },
  middleware: [
    // Assign the tab as responsible for the event
    assignCallerToAction,
    sendSyncedAction,
    // Then check if the current tab is the caller.
    identifyActionCallerInScope,
    // Finally, using store.subscribe, if the current tab is the caller, save the data.
  ],
});

export default store;

// console.log('Store initial:', store.getState());

store.subscribe(() => {
  // console.log('Subscribed:', store.getState());
  if (lastActionCalledBy === tabId) {
    console.log("Change by leader. Saving...")
    storage.setItem("state", store.getState());
  } else {
    // console.log('Subbordinate context; not saving.');
  }

  lastActionCalledBy = null;
});

// On page load, populate each entity store from storage separately using addCharacters (plural).

storage.getItem('state').then(state => {
  // console.log('Loaded:', state);
  if (state !== null) {
    // console.log('Loaded characters:', state.characters);
    store.dispatch(
      loadCharactersFromStorage(state.characters)
    );
  }
});