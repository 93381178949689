import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Global, css } from '@emotion/react';
import theme from './theme';

import App from './app/App';
import store from './app/store';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const globalStyles = css`
  @import url('https://fonts.googleapis.com/css2?family=Ovo&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,700;0,900;1,700;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');
  #root {
    display: flex;
    justify-content: center;
    font-family: ${theme.fonts.body};
    font-weight: ${theme.fontWeights.body};
    font-size: ${theme.fontSizes[4]};
    height: 100%;
  }
  body, html {
    margin: 0;
    font-size: 16px;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
  a {
    color: ${theme.colors.link} !important;
    text-decoration: none;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <Global styles={globalStyles}></Global>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
