import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import theme from '../../theme';

import FormInput from '../../common/FormInput';
import SuggestionItem from './SuggestionItem';

const FormAutoCompleteInput = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  align-items: flex-start;
  line-height: normal;
  width: 100%;
`;

const Suggestion = styled.div`
  grid-row: 1;
  grid-column: 1;
  padding: 1px 2px;
  z-index: -1;
  color: ${theme.colors.label};
  vertical-align: text-bottom;

  @supports (-webkit-touch-callout: none) {
    margin-top: 2px;
    margin-left: 0px;
  }

  @supports not (-webkit-touch-callout: none) {
    margin-top: 0px;
    margin-left: 0px;
  }
`;

const Hide = styled.span`
  visibility: hidden;
`;

const SuggestionList = styled.div`
  position: absolute;
  top: calc(100% + 1rem);
  left: 0;
  right: 0;
  /* border-radius: 0.25rem; */
  overflow: hidden;
  display: none;
  background: ${theme.colors.background};
  z-index: 1;
  box-shadow: -1px 1px 6px 0px rgba(0,0,0,0.5);
  max-height: 35rem;
  overflow-y: auto;
  overscroll-behavior-y: contain;

  &::-webkit-scrollbar {
    display: none;
  }

  ${FormAutoCompleteInput}:focus-within &, &:focus, &:focus-within {
    display: block;
  }
`;

const createSyntheticChangeEvent = (value) => ({
  target: {
    value
  }
});

// TODO: If cursor is at the rightmost position in the field and a suggestion is displayed, fill the suggestion on right arrow press.
// Done :)

//eslint-disable-next-line import/no-anonymous-default-export
export default ({label, index, placeholder, suggestions, value, onChange, onBlur, onKeyDown, onIndexChange, onClickSuggestion}) => {
  const [ suggestionIndex, setSuggestionIndex ] = useState(0);
  
  /**
   * A really scrappy way of pseudo-memoizing the suggestions.
   * When the value in the search field updates, it repulls the autocomplete values.
   * Even if the autocomplete values don't actually change, this still triggers the useEffect hook.
   * This even occurs when switching between identical values (e.g., Conjuration),
   * which in turn causes the switch to be overwritten by the first hook below.
   * Here, we effectively memoize the *length* of the list, which eliminates the issue.
   */
   const [ suggestionsLength, setSuggestionsLength ] = useState(suggestions?.length || 0);
   const [ oldValue, setOldValue ] = useState(value || '');

  useEffect(() => {
    if (suggestions.length !== suggestionsLength || value !== oldValue) {
      setSuggestionIndex(0);
    }
    setSuggestionsLength(suggestions.length);
    setOldValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions, value]);

  useEffect(() => {
    // console.log("Current suggestion:", suggestionIndex, "of", suggestions);
    if (onIndexChange) onIndexChange(suggestionIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionIndex]);

  const handleChange = (e) => {
    // console.log("HANDLE CHANGE WITH", e); // Dev only
    if (onChange) {
      onChange(e);
      setSuggestionIndex(0);
    }
  }

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowUp':
        setSuggestionIndex(index => Math.max(0, index - 1));
        e.preventDefault();
        break;
      case 'ArrowDown':
        setSuggestionIndex(index => Math.min(index + 1, suggestions.length - 1));
        e.preventDefault();
        break;
      case 'ArrowRight':
        if (!suggestions || !suggestions[suggestionIndex] || e.target.value === suggestions[suggestionIndex] || e.target.selectionStart !== e.target.value.length || e.target.selectionStart !== e.target.selectionEnd) {
          break;
        }
        // Fall through if a suggestion is being displayed and the cursor is at the rightmost position.
      case 'Tab':
      case 'Enter':
        if (suggestions && suggestions[suggestionIndex]) {
          // dispatchSyntheticChangeEvent
          // TODO: SEE LINE 101
          onIndexChange(suggestionIndex);
          handleChange(createSyntheticChangeEvent(suggestions[suggestionIndex].title));
        }
        break;
      default:
        break;
    }
    if (onKeyDown) onKeyDown(e);
  };

  // TODO: Instead of (in addition to?) making a change event, we also need to change the matched item in the parent CharacterPaths.js, so that when the user presses enter, the correct item is added to their sheet. This is necessary because two paths have the name Conjuration, and as such it's not possible disambiguate them by title string alone.
  const handleBlur = (e) => {
    if (suggestions && suggestions[suggestionIndex] && e.target.value.length === suggestions[suggestionIndex].title.length) {
      // onMatchedItemChange(suggestions[suggestionIndex]);
      handleChange(createSyntheticChangeEvent(suggestions[suggestionIndex].title));
    }
    if (onBlur) onBlur(e);
  }

  // console.log("Before merged suggestion:", suggestions, suggestions[suggestionIndex]);

  const mergedSuggestion = suggestions && suggestions.length > 0 && suggestions.length > suggestionIndex
    ? <><Hide>{value}</Hide>{suggestions[suggestionIndex].title.slice(value ? value.length : 0)}</>
    : null;
  
  let accessibilitySuggestion = '';
  if (suggestions && suggestions.length > 0 && suggestions.length > suggestionIndex) {
    accessibilitySuggestion = `${suggestionIndex+1} of ${suggestions.length}: "${suggestions[suggestionIndex].title}".`;

    if (suggestions[suggestionIndex].title.toLowerCase() === value.toLowerCase()) {
      accessibilitySuggestion = `${value}. Press enter to add to character.`;
    }
  } else if (value !== "") {
    accessibilitySuggestion = 'No suggestions.';
  }

  return (
    <FormAutoCompleteInput className="grid-1-1">
      <FormInput ariaLabel={`${label}. Autocomplete-enabled. Use up and down arrows to navigate suggestions. Press enter to commit.`} label={label} index={index} placeholder={placeholder} value={value} margins={0} onChange={handleChange} onKeyDown={handleKeyDown} onBlur={handleBlur}/>
      <Suggestion role="alert" aria-label={accessibilitySuggestion} aria-live="polite"><span aria-hidden="true">{mergedSuggestion}</span></Suggestion>
      <SuggestionList tabIndex="0">
        {
          suggestions.map((v, i) => <SuggestionItem key={i} data={v} selected={i === suggestionIndex} query={value} onClick={onClickSuggestion}/>)
        }
      </SuggestionList>
    </FormAutoCompleteInput>
  );
}