import styled from '@emotion/styled/macro';
import theme from '../theme';

const FormGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: ${props => props.noMinHeight ? 'auto' : 'minmax(3rem, auto)'};
  grid-column-gap: 1.5rem;
  grid-row-gap: 1rem;

  @media (max-width: ${theme.breakpoints[0]}) {
    grid-template-columns: 1fr;
  }
`;

export default FormGrid;