import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled/macro';
import theme from '../../theme';

import { newCharacter, mergeCharacter } from './characterModel';
import { selectAll, addCharacter, upsertCharacter } from './charactersSlice';
import { usePageTitle } from '../../common/usePageTitles';

import { parseFileData } from './parsers/import';

import store from '../../app/store';

import Button from '../../common/Button';
import CharacterCard from './CharacterCard';
import MenuButton from '../../common/MenuButton';

const CharacterSelect = styled.div`
  display: grid;
  align-items: stretch;
  justify-content: stretch;
  grid-row-gap: 2rem;
  width: 100%;
  min-width: 24rem;
  grid-auto-flow: row;
  grid-template-columns: 100%;
`;

const SelectHeader = styled.h1`
  font-family: ${theme.fonts.pullquote};
  font-weight: ${theme.fontWeights.pullquote};
  font-size: ${theme.fontSizes[9]};
  margin: calc(${theme.dimensions.topNav} + 1rem) 1rem 0;
  line-height: 0.85;
  text-align: center;
  outline: none;
  word-break: break-word;
`;

const AddContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddButtons = styled.div`
  padding: 1rem;
  color: ${theme.colors.text};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;
`;

const CharacterGrid = styled.div`
  display: grid;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 8rem 2rem;
  grid-template-columns: repeat(auto-fit, 24rem);
  grid-auto-rows: max-content;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1rem;
  justify-content: center;
  align-items: stretch;

  @media (max-width: ${theme.breakpoints[0]}) {
    grid-template-columns: 100%;
    padding: 0 0rem 2rem;
    max-width: 100%;
    width: 24rem;
    margin: 0 auto;
  }

  @media (max-width: 24rem) {
    width: 100%;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

//eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const fileInputRef = useRef(null);

  usePageTitle('Characters');

  const characters = useSelector(selectAll);

  const handleCreateNew = () => {
    store.dispatch(
      addCharacter(
        newCharacter()
      )
    );
  };

  const handleOpenDialog = () => {
    fileInputRef.current.click();
  };

  const clearFileInput = () => {
    fileInputRef.current.files = new DataTransfer().files;
  }

  const handleLoadFile = async (e) => {
    if (e.target.files.length === 0) {
      clearFileInput();
      return;
    }
    
    for (const file of e.target.files) {
      try {
        const text = await file.text();
        const data = parseFileData(text);

        const characterIsUnique = !characters.some(v => v.meta_id === data.meta_id);
        const existingCharacterName = characterIsUnique ? null : characters.find(v => v.meta_id === data.meta_id).basic_name;
        const loadedCharacterNameDiffersFromExisting = !characterIsUnique && (existingCharacterName !== data.basic_name);
        
        // If the character being loaded has a different name than the instance it would replace, denote this in the dialog.
        if (characterIsUnique || window.confirm(`The character ${data.basic_name} already ${loadedCharacterNameDiffersFromExisting ? `exists under a different name (${existingCharacterName})` : 'exists'}. Overwrite?`)) {
          store.dispatch(
            upsertCharacter(
              mergeCharacter(data)
            )
          );
        }
      } catch (err) {
        console.error(`Error occurred during load of ${file.name}:`, err);
        alert(`An error occurred while reading ${file.name}.\nPlease check the file and try again.`);
      }
    }

    clearFileInput();
  }

  return (
    <CharacterSelect>
      <MenuButton/>
      <SelectHeader tabIndex="0" role="heading" aria-level="1">Characters</SelectHeader>
      <AddContainer>
        <AddButtons>
          <Button condensed onClick={handleCreateNew}>Create new</Button>
          <Button condensed onClick={handleOpenDialog}>Load file</Button>
        </AddButtons>
      </AddContainer>
      <CharacterGrid role="list">
        {characters.map((v,i) => (
          <CharacterCard tabIndex="0" key={v.meta_id} role="listitem" character={v}/>
        ))}
      </CharacterGrid>
      <HiddenInput type="file" multiple onChange={handleLoadFile} ref={fileInputRef} accept=".json"/>
    </CharacterSelect>
  );
}