import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect, useRouteMatch, useParams } from 'react-router-dom';

import NotFound from '../../common/NotFound';

import CharacterProfile from './CharacterProfile';
import CharacterInventory from './CharacterInventory';
import CharacterPaths from './CharacterPaths';
// CharacterNotes?

import { selectIds } from './charactersSlice';
import CharacterNavLayout from './CharacterNavLayout';
import MenuButton from '../../common/MenuButton';

const CharacterEditor = () => {
  const { url, path } = useRouteMatch();

  const index = parseInt(useParams().index);
  const id = useSelector(selectIds)[index];

  if (!id) {
    return (
      <>
        <MenuButton/>
        <NotFound/>
      </>
    );
  }

  return (
    <CharacterNavLayout>
      <Switch>
        <Route path={`${path}/profile`}>
          <CharacterProfile/>
        </Route>
        <Route path={`${path}/inventory`}>
          <CharacterInventory/>
        </Route>
        <Route path={`${path}/paths`}>
          <CharacterPaths/>
        </Route>
        <Route exact path={path}>
          <Redirect to={`${url}/profile`}/>
        </Route>
        <Route path="*">
          <NotFound condensed/>
        </Route>
      </Switch>
    </CharacterNavLayout>
  )
};

export default CharacterEditor;