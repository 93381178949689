import React from 'react';
import styled from '@emotion/styled/macro';
import theme from '../../theme';

const AbilityDetails = styled.div`
  font-size: calc(${theme.fontSizes[2]} - 1px);
  line-height: 1.2em;
  color: var(--color, ${theme.colors.border});
  /*color: ${theme.colors.border};*/
  font-weight: 500;
  margin-bottom: 0.75rem;
  letter-spacing: -0.2px;
`;

const Emphasis = styled.span`
  font-family: ${theme.fonts.heading};
  text-transform: lowercase;
`;

const RollPrefix = styled.div`
  position: relative;
  display: inline-flex;
  /*background: ${theme.colors.border};*/
  background: var(--color, ${theme.colors.border});
  font-size: ${theme.fontSizes[1]};
  color: ${theme.colors.background};
  font-family: ${theme.fonts.heading};
  height: 1rem;
  align-items: center;
  justify-content: center;
  text-overflow: clip;
  white-space: nowrap;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
  text-transform: lowercase;
  padding: 0 0.5rem;
`;

const RollOutcome = styled.div`
  margin-bottom: 0.25em;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.colors.bullet};
  }
`;

const ActionCost = styled.div`
  position: relative;
  display: inline-flex;
  /*background: ${theme.colors.border};*/
  background: var(--color, ${theme.colors.border});
  color: ${theme.colors.background};
  font-family: ${theme.fonts.heading};
  font-size: small;
  box-sizing: border-box;
  min-width: 1.3rem;
  height: 1rem;
  padding: 0 0.5rem;
  vertical-align: text-bottom;
  align-items: center;
  justify-content: center;
  text-overflow: clip;
  white-space: nowrap;
  margin-right: 0.75rem;

  &::after {
    position: absolute;
    right: 0;
    top: 0;
    content: '';
    aspect-ratio: 1 / 1;
    height: 100%;
    width: 1rem;
    /*background: ${theme.colors.border};*/
    background: var(--color, ${theme.colors.border});
    transform: translateX(50%) scale(${1 / Math.sqrt(2)}) rotate(-45deg);
    z-index: -1;
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 1.25rem;
`;

const ListItem = styled.li`
  font-size: inherit;
  position: relative;
  margin-bottom: 0.25rem;
  
  &::before {
    position: absolute;
    left: -1rem;
    width: 0px;
    content: '\u25A0';
    color: ${theme.colors.bullet};
    font-size: x-small;
  }
`;

const MissingType = styled.div`
  border: 4px dashed red;
  font-family: cursive;
  border-radius: 4px;
  padding: 10px;
  background: yellow;
  color: red;
  font-size: large;
  font-weight: bold;
`;

const ScreenReaderOnly = styled.div`
  width: 0;
  height: 0;
  overflow: hidden;
`;

const emphasisPattern = /([^\\]?)\*(.*?)([^\\])\*/gi;

const decodeEmphasis = (str) => {
  let tok = str.replaceAll(emphasisPattern,`$1[em]$2$3[em]`);
  let split = tok.split('[em]');
  return split.map(v => v.replaceAll("\\*", "*")).map((v,i) => i%2 ? <Emphasis key={i}>{v}</Emphasis> : v);
};

//eslint-disable-next-line import/no-anonymous-default-export
export default ({data, identifier}) => {
  if (data.type === 'text') {
    return (
      <AbilityDetails>
        {decodeEmphasis(data.content)}
      </AbilityDetails>
    );
  }
  if (data.type === 'action') {
    return (
      <AbilityDetails>
        <ActionCost>
          {data.cost}
          <ScreenReaderOnly>A-P.</ScreenReaderOnly>
        </ActionCost>
        {decodeEmphasis(data.description)}
      </AbilityDetails>
    );
  }
  if (data.type === 'roll') {
    return (
      <AbilityDetails>
        <div>
          <RollPrefix>Roll the die</RollPrefix>
          
        </div>
        {data.outcomes.map((outcome, index) => 
          <RollOutcome key={`${identifier}-roll-${index}`}>
            <ScreenReaderOnly>On </ScreenReaderOnly>
            <Emphasis>{outcome.value}{outcome.value && <>: </>}</Emphasis>
            {outcome.description}
          </RollOutcome>
        )}
      </AbilityDetails>
    );
  }
  if (data.type === 'def') {
    return (
      <AbilityDetails>
        <Emphasis>{data.term}</Emphasis>.&nbsp;
        {data.details}
      </AbilityDetails>
    );
  }
  if (data.type === 'list') {
    return (
      <AbilityDetails>
        <List>
          {data.items.map((item, index) => <ListItem key={`${identifier}-list-${index}`}>{item}</ListItem>)}
        </List>
      </AbilityDetails>
    );
  }
  return <AbilityDetails>
    <MissingType>Unimplemented datum type: {data.type}</MissingType>
  </AbilityDetails>;
};