import styled from '@emotion/styled/macro';
import theme from '../theme';

const MobileLine = styled.div`
  display: contents;
  align-items: flex-end;

  /* line-height: ${props => props.wrap ? 'calc(100% + 1.125rem)' : '100%'}; */

  @media (max-width: ${theme.breakpoints[0]}) {
    display: flex;
    max-width: 100%;
    white-space: ${props => props.shouldWrap ? 'normal' : 'inherit'};

    &:not(:last-of-type) {
      margin-bottom: 1.125rem;
    }
  }
`;

export default MobileLine;