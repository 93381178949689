import { v4 } from 'uuid';
import { getPathDetailsByAbilityId, getAbilityDetailsByTitle } from '../../../data/searchFunctions';

export class SaveIntegrityError extends Error {
  constructor() {
    super();
    this.name = Object.getPrototypeOf(this).constructor.name;
  }
}

export class SaveValidationError extends Error {
  constructor() {
    super();
    this.name = Object.getPrototypeOf(this).constructor.name;
  }
}

const patches = {
  'legendaryAbilities': (data) => {
    data.paths = data.paths.map(path => {
      if (!path.hasOwnProperty('type')) {
        return Object.assign({type: 'path'}, path);
      } else {
        return path;
      }
    });
    return data;
  }
};

const withPatches = (patchNames, data) => {
  let d = data;
  for (let p of patchNames) {
    d = patches[p](d);
  }
  return d;
}

const parsers = {
  '1.0': (data) => {
    const structure = {
      meta_id: v4(),
      meta_version: '2.0',
      meta_updated: new Date().getTime(),
      basic_name: data.name,
      basic_pronouns: data.pronouns,
      basic_age: data.age,
      basic_height: data.height,
      basic_role: data.role,
      feature_body: data.features.body,
      feature_face: data.features.face,
      feature_vibe: data.features.vibe,
      style_clothing_1: data.style.clothing[0],
      style_clothing_2: data.style.clothing[1],
      style_movement: data.style.movement,
      origin_home: data.origin.home,
      origin_community: data.origin.community,
      spirit_ideal: data.spirit.ideal,
      spirit_flaw: data.spirit.flaw,
      spirit_dream: data.spirit.dream,
      points_health: data.points.hp,
      points_action: data.points.ap,
      inventory: data.inventory
    };

    let savedPaths = [];

    for (const ability of data.abilities) {
      if (!ability) continue;

      const abilityDetails = getAbilityDetailsByTitle(ability);
      if (abilityDetails) {
        const pathDetails = getPathDetailsByAbilityId(abilityDetails.id);
        if (pathDetails) {
          const savedPathsIndex = savedPaths.findIndex(v => v.id === pathDetails.id);
          if (savedPathsIndex > -1) {
            if (savedPaths[savedPathsIndex].level > abilityDetails.meta.level) {
              savedPaths[savedPathsIndex].level = abilityDetails.meta.level;
            }
          } else {
            savedPaths.push({
              id: pathDetails.id,
              level: abilityDetails.meta.level
            });
          }
        }
      }
    };

    structure.paths = savedPaths;

    return withPatches(['legendaryAbilities'], structure);
  },
  '2.0': (data) => {
    const structure = {};
    const saveKeys = ['meta', 'basic', 'features', 'style', 'origin', 'spirit', 'points'];

    if (data.meta.type !== 'Character') throw new SaveValidationError(`This is not a character save file.`);

    for (const key of saveKeys) {
      let modKey = (key === 'features' ? 'feature': key);
      for (const nestedKey of Object.keys(data[key])) {
        structure[`${modKey}_${nestedKey}`] = data[key][nestedKey];
      }
    }
    
    structure.paths = data.paths;
    structure.inventory = data.inventory;

    delete data.meta.type;
    return withPatches(['legendaryAbilities'], structure);
  }
};

parsers['2.1'] = parsers['2.0'];

export const withParser = (version, data) => {
  if (!parsers.hasOwnProperty(version)) {
    console.log("No version available");
    throw new SaveValidationError(`Invalid version number! Unable to load save.`);
  }
  
  try {
    return parsers[version](data);
  } catch (err) {
    console.log(err);
    throw new SaveIntegrityError(`A parsing error occurred. Loading failed.`);
  }
}

export const parseFileData = (dataString) => {
  try {
    const data = JSON.parse(dataString);
    let version = '1.0';

    if (data?.meta?.version) {
      version = data.meta.version;
    }

    return withParser(version, data);
  } catch (err) {
    console.log(err.message);
    throw new SaveIntegrityError(`A parsing error occurred. Loading failed.`);
  }
}