import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const charactersAdapter = createEntityAdapter({
  selectId: entity => entity.meta_id
});

const charactersSlice = createSlice({
  name: 'character',
  initialState: charactersAdapter.getInitialState(),
  reducers: {
    addCharacter: charactersAdapter.addOne,
    addCharacters: charactersAdapter.addMany,
    updateCharacter: charactersAdapter.updateOne,
    upsertCharacter: charactersAdapter.upsertOne,
    deleteCharacter: charactersAdapter.removeOne,
    setCharacterFieldById(state, action) {
      charactersAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          [action.payload.field]: action.payload.value,
          meta_updated: new Date().getTime()
        }
      });
    },
    deleteCharacterById(state, action) {
      charactersAdapter.removeOne(state, action.payload.id);
    },
    loadCharactersFromStorage(state, action) {
      // console.log('!', action.payload);
      // console.log('>', action.payload.entities);
      charactersAdapter.upsertMany(state, Object.values(action.payload.entities));
    }
  }
});

const globalizedSelectors = (state) => state.characters;

export const {
  addCharacter,
  addCharacters,
  updateCharacter,
  upsertCharacter,
  deleteCharacter,
  deleteCharacterById,
  setCharacterFieldById,
  loadCharactersFromStorage
} = charactersSlice.actions;
export const characterActions = charactersSlice.actions;

export default charactersSlice.reducer;

const selectors = charactersAdapter.getSelectors(globalizedSelectors);
export const { selectIds, selectEntities, selectAll, selectById } = selectors;