import styled from '@emotion/styled/macro';
import theme from '../theme';

const Button = styled.button`
  display: inline-block;
  padding: ${props => props.compact ? '8px 12px' : '16px 32px'};
  font-family: ${theme.fonts.heading};
  font-weight: 400;
  font-size: 22px;
  text-transform: lowercase;
  border: 1px solid ${theme.colors.border};
  background: white;
  color: inherit !important;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.danger {
    :hover, :focus, :active {
      background: #dc2027;
      border-color: #dc2027;
      color: white !important;
    }
  }
`;

const FlatButton = styled(Button)`
  &:hover, &:focus {
    background: #ddd;
  }

  &:active {
    transform: translateY(1px);
  }
`;

const RaisedButton = styled(Button)`
  transform: translate(0, 0);
  box-shadow: 6px 6px 0 ${theme.colors.border};
  outline: none;

  &:hover, &:focus {
    transform: translate(2px, 2px);
    box-shadow: 4px 4px 0 ${theme.colors.border};
  }

  &:active {
    transform: translate(4px, 4px);
    box-shadow: 2px 2px 0 ${theme.colors.border}; 
  }
`;

const CondensedButton = styled(RaisedButton)`
  padding: 16px;
`;

//eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  if (props.raised) {
    return <RaisedButton {...props}/>;
  } else if (props.condensed) {
    return <CondensedButton {...props}/>;
  } else {
    return <FlatButton {...props}/>;
  }
};