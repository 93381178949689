import { v4 } from 'uuid';
import version from '../../data/version';

// Flattened out versus the original format to simplify getting and setting.
//  - metadata contains data for file reading.
//  - profile contains data used by the CharacterProfile component, some of which is shared (name, hp, ap).
//  - abilities contains data used by the CharacterAbilities component.
//  - inventory contains data used by the CharacterInventory component.

const characterModel = {
  meta_id: '',
  meta_type: 'Character',
  meta_version: version,
  meta_updated: null,
  basic_name: '',
  basic_pronouns: '',
  basic_age: '',
  basic_height: '',
  basic_role: '',
  feature_body: '',
  feature_face: '',
  feature_vibe: '',
  style_clothing_1: '',
  style_clothing_2: '',
  style_movement: '',
  origin_home: '',
  origin_community: '',
  spirit_ideal: '',
  spirit_flaw: '',
  spirit_dream: '',
  points_health: 10,
  points_action: 10,
  paths: [],
  inventory: ['', '', '', '', '', '', '', '', '', '', '', '']
};

export default characterModel;

export const newCharacter = () => {
  return {
    ...characterModel,
    meta_id: v4(),
    meta_updated: new Date().getTime()
  };
}

export const mergeCharacter = (existingData) => {
  return {
    ...characterModel,
    meta_id: v4(),
    meta_updated: new Date().getTime(),
    ...existingData
  };
}