import React from 'react';
import ContentEditable from 'react-contenteditable';
import styled from '@emotion/styled/macro';
import theme from '../theme';

const FormTextArea = styled.div`
  display: inline-flex;
  position: relative;
  flex: 1 0 50%;
  min-height: 4.5em;
  line-height: initial;
  margin-bottom: 2px;
  font-size: 22px;
  box-sizing: border-box;
  border: 1px solid ${theme.colors.text};
  border-radius: ${theme.radii.page};

  &:focus-within {
    border-color: ${theme.colors.link};
    box-shadow: 0px 0px 0 1px ${theme.colors.link};
  }

  @media (max-width: ${theme.breakpoints[0]}) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

const TextArea = styled(ContentEditable)`
  box-sizing: border-box;
  border: none;
  position: relative;
  outline: none;
  resize: none;
  background: none;
  color: ${theme.colors.text};
  font-family: inherit;
  font-size: inherit;
  width: 100%;
  word-break: break-word;
  padding: 0.75em;
  white-space: pre-wrap;
`;

const FieldNumber = styled.div`
  position: absolute;
  left: 0;
  top: 0.25rem;
  margin-left: -0.75rem;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid ${theme.colors.border};
  background: ${theme.colors.border};
  color: ${theme.colors.background};
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[2]};
  font-weight: ${theme.fontWeights.heading};

  user-select: none;

  ${FormTextArea}:focus-within & {
    background: ${theme.colors.background};
    border-color: ${theme.colors.link};
    color: ${theme.colors.link};
  }
`;

//eslint-disable-next-line import/no-anonymous-default-export
export default ({label, number, value, onChange}) => {
  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'none';
  }

  const handleDrop = (e) => {
    e.preventDefault();
  };

  return (
    <FormTextArea>
      <FieldNumber aria-hidden="true">{number}</FieldNumber>
      <TextArea
        role="textbox"
        aria-label={label}
        html={value}
        onChange={onChange}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      />
    </FormTextArea>
  );
};