import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import theme from '../theme';

import { Menu as MenuIcon } from '@emotion-icons/heroicons-outline';

import {ReactComponent as QuestLogo} from '../quest_fan.svg';

const Layout = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: [nav-start] ${theme.dimensions.topNav} [content-start] 1fr [content-end];
  grid-template-columns: [menu-start] ${theme.dimensions.topNav} [content-start] 1fr [content-end];
`;

const LeftNav = styled.div`
  position: fixed;
  top: 0;
  left: ${props => props.open ? '0' : `calc(-1 * ${theme.dimensions.leftNav})`};
  bottom: 0;
  width: ${theme.dimensions.leftNav};
  transition: left 0.25s ease-out;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  padding-top: 0;
  background: ${theme.colors.border};
  color: rgb(237, 237, 237);
  z-index: 14;
  max-width: 100%;
`;

const MenuBackdrop = styled.div`
  display: block;
  pointer-events: none;
  width: 100vw;
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  left: 100%;
  top: 0;
  bottom: 0;

  @media (max-width: ${theme.breakpoints[0]}) {
    .menu-showing & {
      opacity: 1;
      transition: opacity 0.25s ease-in-out;
      pointer-events: all;
      cursor: pointer;
    }
  }
`;

const PageContent = styled.div`
  grid-row-start: nav-start;
  grid-row-end: content-end;
  grid-column-start: menu-start;
  grid-column-end: content-end;
  display: flex;
  justify-content: center;
  overflow-y: visible;
  overflow-x: hidden;
  transition: margin-left 0.25s ease-out;
  max-width: 100%;
  
  .menu-showing & {
    margin-left: ${theme.dimensions.leftNav};
  }

  @media (max-width: ${theme.breakpoints[0]}) {
    transition: none;

    .menu-showing & {
      margin-left: 0;
    }
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.background};
  margin-bottom: 2rem;
  height: ${theme.dimensions.topNav};

  & a {
    color: ${theme.colors.background} !important;

    svg {
      margin-top: 0.45rem;
    }
  }
`;

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: ${theme.fonts.heading};
  text-transform: lowercase;
  font-weight: 500;
  
  & a {
    color: rgb(237, 237, 237) !important;
    margin-bottom: 1rem;
  }
`;

const MenuButton = styled.div`
  position: ${props => props.interior ? 'absolute' : 'fixed'};
  top: 0;
  left: 0;
  width: ${theme.dimensions.topNav};
  height: ${theme.dimensions.topNav};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${props => props.interior ? 'rgb(237, 237, 237)' : theme.colors.background};
  z-index: 11;
`;

const isDesktopViewport = () => {
  const winWidth = window.innerWidth;
  const breakpoint = parseFloat(theme.breakpoints[0]);
  const remValue = parseFloat(getComputedStyle(document.documentElement).fontSize);
  return winWidth >= (breakpoint + 4) * remValue;
}

//eslint-disable-next-line import/no-anonymous-default-export
export default ({children}) => {
  const [ showMenu, setShowMenu ] = useState(isDesktopViewport());

  const handleMenuToggle = (e) => {
    setShowMenu(open => !open);
  }

  const dismissMenuIfOpen = (e) => {
    setShowMenu(false);
  }

  return (
    <Layout className={showMenu ? 'menu-showing' : ''}>
      <MenuButton onClick={handleMenuToggle}/>
      <LeftNav open={showMenu}>
        <MenuButton interior onClick={handleMenuToggle}>
          <MenuIcon size="24"/>
        </MenuButton>
        <Logo>
          <NavLink to="/">
            <QuestLogo width="100"/>
          </NavLink>
        </Logo>
        <LinkList>
          <NavLink to="/characters">Characters</NavLink>
          {/*<NavLink to="/worlds">Worlds</NavLink>*/}
          {/*<NavLink to="/campaigns">Campaigns</NavLink>*/}
        </LinkList>
        <MenuBackdrop onClick={dismissMenuIfOpen}/>
      </LeftNav>
      <PageContent>
        {children}
      </PageContent>
    </Layout>
  );
}