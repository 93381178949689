import styled from '@emotion/styled/macro';

const FormRowLayout = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: row;
`;

export default FormRowLayout;